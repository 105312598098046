import vSelect from "vue-select";
import VModal from "vue-js-modal";
import "vue-select/dist/vue-select.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import PrettyCheckbox from "pretty-checkbox-vue";
import Vue from "vue";
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

window.Vue = require("vue").default;
Vue.component("v-select", vSelect);
Vue.use(VModal, {
    componentName: "v-model"
});
Vue.use(VueSweetalert2);
Vue.use(PrettyCheckbox);

Vue.filter("prettyNumber", num => {
    const si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(1).replace(rx, "$1") + si[i].symbol;
});

Vue.filter("formattedNumber", num =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Pages
 */
Vue.component("login-page", () => import("./pages/Login.vue"));
Vue.component("email-page", () => import("./pages/Email.vue"));
Vue.component("password-confirm-page", () =>
    import("./pages/PasswordConfirm.vue")
);
Vue.component("password-reset-page", () => import("./pages/PasswordReset.vue"));
Vue.component("register-page", () => import("./pages/Register.vue"));
Vue.component("email-verify-page", () => import("./pages/EmailVerify.vue"));
Vue.component("register-steps-page", () => import("./pages/RegisterSteps.vue"));
Vue.component("profile-page", () => import("./pages/Profile.vue"));
Vue.component("profile-feature", () => import("./pages/ProfileFeature.vue"));
Vue.component("payment-page", () => import("./pages/Payment.vue"));
Vue.component("jobs-page", () => import("./pages/Jobs/Index.vue"));
Vue.component("job-show-page", () => import("./pages/Jobs/Show.vue"));
Vue.component("jobs-add-page", () => import("./pages/Jobs/Add.vue"));
Vue.component("casting_calls-page", () =>
    import("./pages/CastingCalls/Index.vue")
);
Vue.component("casting_call-show-page", () =>
    import("./pages/CastingCalls/Show.vue")
);
Vue.component("casting_calls-add-page", () =>
    import("./pages/CastingCalls/Add.vue")
);
Vue.component("members-page", () => import("./pages/Members.vue"));

Vue.component("videos-page", () => import("./pages/videos/Index.vue"));
Vue.component("video-show-page", () => import("./pages/videos/Show.vue"));

Vue.component("audios-page", () => import("./pages/audios/Index.vue"));
Vue.component("audio-show-page", () => import("./pages/audios/Show.vue"));

/**
 * Globals
 */
Vue.component("navbar-global", () => import("./global/Navbar.vue"));
Vue.component("footer-global", () => import("./global/Footer.vue"));
Vue.component("alert-global", () => import("./global/Alert.vue"));
Vue.component("ad-banner", () => import("./components/advertise/banner.vue"));

/**
 * Components
 */
Vue.component(
    "example-component",
    require("./components/ExampleComponent.vue").default
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
// Vue.component('select-option', {
//     template: ' \
//         <select v-model="innerValue"> \
//             <option v-if="first" :key="getValue(first) || \'first\'" :value="getValue(first)">{{getText(first)}}</option> \
//             <template v-for="group in optionGroups"> \
//                 <optgroup v-if="group.key" :label="group.key"> \
//                     <option v-for="item in group.values" :key="getValue(item)" :value="getValue(item)">{{getText(item)}}</option> \
//                 </optgroup> \
//                 <option v-else v-for="item in group.values" :key="getValue(item)" :value="getValue(item)">{{getText(item)}}</option> \
//             </template> \
//         </select> \
//     ',
//     props: ['value', 'options', 'first', 'config'],
//     computed: {
//         /*
//         Using a computed property with getter/setter because of a bug in vue when you try to change the value and options
//         at the same time.
//         See: https://stackoverflow.com/q/49431312/3043529
//         */
//         innerValue: {
//             get: function() { return this.value; },
//             set: function(newValue) { this.update(newValue); }
//         },
//         optionGroups: function () {
//             var app = this;
//
//             var groupBy = function(array, selector) {
//                 var result = [];
//                 var keys = [];
//
//                 var reducer = function(result, item) {
//                     var key = selector(item) || '';
//                     if(keys.indexOf(key) === -1) {
//                         keys.push(key);
//                     }
//                     (result[key] = result[key] || []).push(item);
//                     return result;
//                 }
//
//                 var groups = array.reduce(reducer, {});
//
//                 for(var i = 0; i < keys.length; i++){
//                     var key = keys[i];
//                     result.push({key: key, values: groups[key]})
//                 }
//
//                 return result;
//             }
//
//             return groupBy(this.options, function(x) { return app.getGroup(x); });
//         }
//     },
//     methods: {
//         update: function(value) {
//             this.$emit('input', value);
//         },
//         selectFirst: function () {
//             var app = this;
//             var exist = this.options.some(function(x) { return app.getValue(x) === app.value; });
//             if(!exist && this.options.length > 0) {
//                 if(this.first){
//                     this.update(this.getValue(this.first));
//                 }else {
//                     this.update(this.getValue(this.options[0]));
//                 }
//             }
//         },
//         getValue: function(item) {
//             return this.isString(item)
//                 ? item
//                 : (item.hasOwnProperty('Value') ? item.Value : item.value);
//         },
//         getText: function(item) {
//             if(this.isString(item)){
//                 return item;
//             } else if(item.hasOwnProperty('Text')) {
//                 return item.Text;
//             } else if (item.hasOwnProperty('text')) {
//                 return item.text;
//             } else {
//                 return this.getValue(item);
//             }
//         },
//         getGroup: function(item) {
//             return item.hasOwnProperty('Group') ? item.Group : item.group;
//         },
//         isString: function(item) {
//             return typeof item === 'string' || item instanceof String;
//         }
//     },
//     mounted: function () {
//         this.selectFirst();
//     },
//     watch: {
//         options: {
//             deep: true,
//             handler: function(newValue, oldValue) {
//                 this.selectFirst();
//             }
//         },
//         first: {
//             deep: true,
//             handler: function (newValue, oldValue){
//                 this.selectFirst();
//             }
//         }
//     }
// });
const app = new Vue({
    el: "#app"
});
